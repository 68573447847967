import { ResourceService } from '../../../http/resource/resource.service';
import { HttpClient } from '@angular/common/http';
import { FieldsSerializer } from './fields.serializer';
import { environment } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Fields } from './fields';

@Injectable({
  providedIn: 'root'
})
export class FieldStandardService extends ResourceService<Fields> {
  private allFields: Fields[] = [];
  
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      `${environment.API}`,
      'selective-process/standard-fields',
      new FieldsSerializer());
  }

  public async getFields(): Promise<Fields[]> {
    if(this.allFields.length === 0) {
      await this.loadFields();
    }
    return this.allFields;
  }

  public setFields(fields: Fields[]) {
    this.allFields = fields;
  }

  public async loadFields() {
    const fields = await this.getAllFieldsPromise();
    if (fields) {
      this.setFields(fields);
    }
  }

  public getAllFieldsPromise(): Promise<any> {
    return this.createArrayNoParam().toPromise()
  }
}

<form [formGroup]="formulario">
  <div
    [style.display]="toggle === true ? 'none' : 'none'"
    [ngClass]="{'ps-display-toggle-param': toggle}"
    class="ps-adds-pareaments-toggle-content"
    fxLayout="row">
    <div fxFlex>
      <mat-slide-toggle
        color="primary"
        #matSlideToggle="matSlideToggle"
        (toggleChange)="changeToggle(matSlideToggle)"
        [formControlName]="identification.toggle_name">
        {{nameToggle}}
      </mat-slide-toggle>
    </div>
  </div>
  <div
    class="ps-adds-pareaments-input-content"
    [formArrayName]="identification.control_name"
    *ngFor="let item of p.controls; let i = index;">
    <div
      *ngIf="f[identification.toggle_name].value"
      [formGroupName]="i"
      class="ps-row"
      fxLayout="row"
      fxLayoutGap="20px">
      <mat-form-field
        *ngIf="nameFields.length === 0"
        appearance="fill"
        [fxFlex]="fxFlex">
        <mat-label>Nome campo</mat-label>
        <input
          [disableControl]="item.get('fixed_param').value !== 1"
          matInput
          #input
          formControlName="name"
          [errorStateMatcher]="matcher">
        <mat-error *ngIf="item.get('name').hasError('required')">
          Campo obrigatório
        </mat-error>
      </mat-form-field>
      <mat-form-field
        *ngIf="nameFields.length > 0"
        appearance="fill"
        [fxFlex]="fxFlex">
        <mat-label>Nome campo</mat-label>
        <mat-select
          (selectionChange)="disabledSelectedContext()"
          formControlName="name"
          [errorStateMatcher]="matcher">
          <mat-option
            *ngFor="let name of nameFields"
            [disabled]="name.disabled"
            [value]="name.name ? name.name : name">
            {{name.name ? name.name : name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="item.get('name').hasError('required')">
          Campo obrigatório
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" [fxFlex]="fxFlex">
        <mat-label>Tipo do campo</mat-label>
        <mat-select
          (selectionChange)="changeParameterTypeId()"
          formControlName="parameter_type_id"
          [errorStateMatcher]="matcher">
          <mat-option [value]="1">Campo sistema</mat-option>
          <mat-option [value]="2">Valor fixo</mat-option>
        </mat-select>
        <mat-error *ngIf="item.get('parameter_type_id').hasError('required')">
          Campo obrigatório
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="item.get('parameter_type_id').value == 2" appearance="fill" [fxFlex]="fxFlex">
        <mat-label>Valor fixo</mat-label>
        <input matInput #input formControlName="fixed_value" [errorStateMatcher]="matcher">
        <mat-error *ngIf="item.get('fixed_value').hasError('valueFixedValue')">
          Campo obrigatório
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="item.get('parameter_type_id').value == 1" appearance="fill" [fxFlex]="fxFlex">
        <mat-label>Campo sistema</mat-label>
        <input
          type="text"
          matInput
          formControlName="field_id"
          [errorStateMatcher]="matcher"
          [matAutocomplete]="auto">
        <mat-autocomplete
          #auto="matAutocomplete"
          autoActiveFirstOption="true"
          [displayWith]="displayFn">
          <mat-option *ngFor="let option of filteredOptions[i] | async"
          [matTooltip]="option.label"
          matTooltipPosition="below"
          matTooltipClass="ps-tooltip-content"
          [matTooltipShowDelay]="200"
          [value]="option"
          >
            {{option.label}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="item.get('field_id').hasError('valueFieldId')">
          Campo obrigatório
        </mat-error>
        <mat-error
          *ngIf="item.get('field_id').hasError('match') && !item.get('field_id').hasError('valueFieldId')">
          Selecione o campo
        </mat-error>
      </mat-form-field>
      <div
        fxLayoutGap="15px"
        fxFlex fxLayoutAlign="start center"
        class="ps-adds-pareaments-btn-content">
        <a
          [disabled]="item.get('fixed_param').value === 1 || disabled()"
          class="ps-adds-pareaments-del"
          color="primary"
          mat-stroked-button
          (click)="removeItem(i)">
          <mat-icon>delete</mat-icon>
        </a>
        <a
          class="ps-adds-pareaments-add"
          color="primary"
          mat-stroked-button
          [disabled]="disableAddNewContextButton()"
          (click)="addItem([],i)">
          <mat-icon>add</mat-icon>
        </a>
      </div>
    </div>
  </div>
</form>

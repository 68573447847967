import { Component, OnInit, Input, OnChanges, ViewChild, SimpleChange, SimpleChanges, Output, EventEmitter, DoCheck } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-totvs-query-fields',
  templateUrl: './totvs-query-fields.component.html',
  styleUrls: ['./totvs-query-fields.component.scss']
})
export class TotvsQueryFieldsComponent implements OnInit, DoCheck {

  @Input() displayedColumns: any;
  @Input() dataSource;
  @Input() removeRow = false;
  @Input() fields;
  @Output() clickedButtonRemove = new EventEmitter();
  @ViewChild('matTable', { static: true }) table: MatTable<any>;
  public options = {
    autoHide: false, scrollbarMinSize: 100
  };

  constructor() { }

  ngOnInit(): void {
    if (this.fields) {
      this.buildData();
    }
  }

  private buildData() {
    let array = new Array();
    this.dataSource.filter(value => {
      const obj = this.fields.find(o => o.field_id === value.field_id);
      if (obj) {
        array = [...array, ...obj.display_table];
      }
    });
    this.dataSource = array;
  }

  ngDoCheck() {
    if (this.dataSource) {
      if (typeof this.dataSource.length !== 'undefined') {
        if (this.dataSource.length > 0 && this.removeRow) {
          if (this.table.dataSource) {
            this.table.renderRows();
          }
        }
      }
    }
  }

  actionButtonRemove(param) {
    this.clickedButtonRemove.emit(param);
  }
}

import { Step } from './step';

export class StepSerializer {

  fromJson(json: any): Step {
    const step = new Step();
    if (typeof json.success !== 'undefined') {
      step.success = json.success;
      step.data = this.toJson(json.data);
      return step;
    } else {
      return this.toJson(json);
    }
  }

  toJson(step: Step): any {
    if (typeof step === 'object') {
      return {
        id: step.id,
        step_id: step.id,
        background: step.background,
        stage_id: step.stage_id,
        position: step.position,
        position_to: step.position,
        name: step.name,
        deleted_at: step.deleted_at,
        created_at: step.created_at,
        updated_at: step.updated_at,
        content: step.content,
        standard_fields: step.standard_fields,
        standard_components: step.standard_components,
        changed: 0
      };
    } else {
      return step;
    }
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { StageOpeningService } from '@app/services/selective-processes/stage/stage-opening.service';
import { LoaderService } from '@shared/components/loader/loader.service';
import { SelectiveProcessesOpeningPageService } from '@app/services/selective-processes/selective-processes-opening-page.service';
import { StepSelectedStageService } from '@app/services/selective-processes/step/step-selected-stage.service';
import { convertToInt } from '@shared/utils/util';
import { FieldStandardService } from '@app/services/selective-processes/fields/fields-standard.service';
import { ComponentStandardService } from '@app/services/selective-processes/component/component-standard.service';
import { VisualIdentityPortalService } from '@app/services/portal/visual-identity-portal/visual-identity-portal.service';
import { FeedbackService } from '@app/services/selective-processes/feedback/feedback.service';
import { Step } from '@app/services/selective-processes/step/step';
import { Stage } from '@app/services/selective-processes/stage/stage';
import { Feedback } from '@app/services/selective-processes/feedback/feedback';
import { SelectiveProcesses } from '@app/services/selective-processes/selective-processes';
import { Fields } from '@app/services/selective-processes/fields/fields';
import { Component } from '@app/services/selective-processes/component/component';
import { VisualIdentity } from '@app/services/general-settings/visual-identity/visual-identity';

interface SelectiveEditorInterface {
  stageId: string;
  selectiveId: string;
  stages: Array<Stage>;
  steps: Array<Step>;
  feedback: Array<Feedback>;
  selectiveProcess: SelectiveProcesses;
  standardFields: Array<Fields>;
  componentStandard: Array<Component>;
  visualIdentity: VisualIdentity;
}

@Injectable({
  providedIn: 'root'
})
export class SelectiveEditorService {

  public data: SelectiveEditorInterface;

  private buildTheData = new BehaviorSubject([]);
  public selectiveEditorData = this.buildTheData.asObservable();

  public checkLoad = false;
  public ItCameFromTheEditorSelectiveProcess = false;

  public stageData;
  public stepData;
  public feedbackData;
  public selectiveProcessData;
  public componentsData;
  public visualIdentityData;
  public fieldsData;

  constructor(
    private stageOpenService: StageOpeningService,
    public loaderService: LoaderService,
    private selectiveProcessesOpeningPageService: SelectiveProcessesOpeningPageService,
    private stepSelectedStageService: StepSelectedStageService,
    private feedbackService: FeedbackService,
    private fieldStandardService: FieldStandardService,
    private componentStandardService: ComponentStandardService,
    private visualIdentityPortalService: VisualIdentityPortalService,
    private route: Router,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<any> | Promise<any> | any {

    this.loaderService.setChangesLoaderState(true);

    return this.getStage(route.params.id)
      .then(stageData => this.stageData = stageData)
      .then(() => this.getSelectiveProcesses(route.params.id))
      .then(selectiveProcessData => this.selectiveProcessData = selectiveProcessData.data)
      .then(() => this.getStep(route.params.id))
      .then(stepData => this.stepData = stepData)
      .then(() => this.addStepDataInTheCurrentStage(route.params.id))
      .then(() => this.getFeedback(route.params.id))
      .then(feedbackData => this.feedbackData = feedbackData)
      .then(() => this.addFeedbackDataInTheCurrentStage(route.params.id))
      .then(() => this.getVisualIdentity())
      .then((visualIdentityData) => this.visualIdentityData = visualIdentityData.data)
      .then(() => this.getFields())
      .then((fieldsData) => {
        this.fieldsData = fieldsData;
        this.fieldStandardService.setFields(fieldsData);
      })
      .then(() => this.getComponents())
      .then((componentData) => this.componentsData = componentData)
      .catch(error => this.loaderService.setChangesLoaderState(false, error.body, '', 'error', error.title, 100000))
      .then((error) => {
        if (error) {
          this.data = {
            selectiveId: this.getId(route.params.id),
            stageId: this.getStageId(route.params.id),
            selectiveProcess: this.selectiveProcessData,
            stages: this.stageData,
            steps: this.stepData,
            feedback: this.feedbackData,
            standardFields: this.fieldsData,
            componentStandard: this.componentsData,
            visualIdentity: this.visualIdentityData
          };
          this.setComponentOnForm();
          this.setData(this.data);
          this.loaderService.setChangesLoaderState(false);
          return this.data;
        }
      });
  }

  private setComponentOnForm() {
    this.data.steps.map((step) => {
      step.standard_components = [...this.data.componentStandard];
    });

    this.data.feedback.map((feedback) => {
      feedback.standard_components = [...this.data.componentStandard];
    });
  }

  /**
   * setData
   * @param param Valores para ser usandos no editor.
   */
  public setData(param: any): void {
    this.buildTheData.next(param);
  }

  /**
   * getData
   * Retorna todos os dados setados
   */
  public getData() {
    let dataReturn: any = {};
    this.selectiveEditorData.subscribe(
      (data: any) => {
        dataReturn = data;
      }
    );
    return dataReturn;
  }


  /**
   * getDataStage
   * Retorna os dados da stage atual
   */
  public getDataStage(): Stage {
    let dataReturn: any = {};
    this.selectiveEditorData.subscribe(
      (data: any) => {
        data.stages.filter(value => {
          if (value.id === convertToInt(data.stageId)) {
            dataReturn = value;
          }
        });
      }
    );
    return dataReturn;
  }

  /**
   * getDataStageID
   * Retorna os dados da stage atual
   * @param id da stage
   */
  public getDataStageID(id): Stage {
    let dataReturn: any = {};
    this.selectiveEditorData.subscribe(
      (data: any) => {
        data.stages.filter(value => {
          if (value.id === convertToInt(id, null)) {
            dataReturn = value;
          }
        });
      }
    );
    return dataReturn;
  }

  /**
   * getDataStepID
   * Retorna os dados do passo atual
   * @param obj do passo
   */
  public getDataStepID(obj): Step {
    let dataReturn: any = {};
    this.selectiveEditorData.subscribe(
      (data: any) => {
        data.stages.filter(value => {
          if (value.id === convertToInt(data.stageId)) {
            value.steps.filter(valueStep => {
              if (valueStep.id === null) {
                if (valueStep.position === obj.position) {
                  dataReturn = valueStep;
                }
              } else {
                if (valueStep.id === obj.id) {
                  dataReturn = valueStep;
                }
              }
            });
          }
        });
      }
    );
    return dataReturn;
  }

  /**
   * setDataStepID
   * Atualiza o valor do step atual
   * @param obj do step
   */
  public setDataStepID(obj): void {
    this.data.stages.filter((value, keyStage) => {
      if (value.id === convertToInt(this.data.stageId)) {
        value.steps.filter((valueStep, key) => {
          if (valueStep.id === null) {
            if (valueStep.position === obj.position) {
              this.data.stages[keyStage].steps[key] = obj;
            }
          } else {
            if (valueStep.id === obj.id) {
              this.data.stages[keyStage].steps[key] = obj;
            }
          }
        });
      }
    });
  }

  /**
   * getDataFeedbackID
   * Retorna os dados do feedback atual
   * @param obj do feedback
   */
  public getDataFeedbackID(obj): Feedback {
    let dataReturn: any = {};
    this.selectiveEditorData.subscribe(
      (data: any) => {
        data.stages.filter(value => {
          if (value.id === convertToInt(data.stageId)) {
            value.feedback.filter((valueFeed, key) => {
              if (valueFeed.id === null) {
                if (valueFeed.position === obj.position) {
                  dataReturn = valueFeed;
                }
              } else {
                if (valueFeed.id === obj.id) {
                  dataReturn = valueFeed;
                }
              }
            });
          }
        });
      }
    );
    return dataReturn;
  }

  /**
   * setDataFeedbackID
   * Atualiza o valor do feedback atual
   * @param obj do step
   */
  public setDataFeedbackID(obj): void {
    this.data.stages.filter((value, keyStage) => {
      if (value.id === convertToInt(this.data.stageId)) {
        value.feedback.filter((valueFeed, key) => {
          if (valueFeed.id === null) {
            if (valueFeed.position === obj.position) {
              this.data.stages[keyStage].feedback[key] = obj;
            }
          } else {
            if (valueFeed.id === obj.id) {
              this.data.stages[keyStage].feedback[key] = obj;
            }
          }
        });
      }
    });
  }

  /**
   * getStage
   * Busca os dados da etapa
   * @param paramUrl parâmetro passado na url
   */
  private getStage(paramUrl) {
    if (this.getStageId(paramUrl) === null && !this.stageData) { //nenhuma etapa cadastrada
      return new Promise(resolve => resolve([]));
    }
    if (!this.stageData && this.getStageId(paramUrl) !== null) { //existe etapa cadastrada
      return this.stageOpenService.listParam(paramUrl).toPromise();
    }
    return this.stageOpenService.listParam(paramUrl).toPromise();
  }

  /**
   * getProcessId
   * Busca o id do portal
   * @param paramUrl parâmetro passado na url
   */
  private getPortalId(paramUrl) {
    
  }

  /**
   * getSelectiveProcesses
   * Busca os dados do processo seletivo
   * @param paramUrl parâmetro passado na url
   */
  private getSelectiveProcesses(paramUrl) {
    return this.selectiveProcessesOpeningPageService.read(this.getId(paramUrl)).toPromise();
  }

  /**
   * Busca os passos da etapa
   * @param paramUrl id do processo seltivo e da etapa
   */
  private getStep(paramUrl): Promise<any> {
    if (this.getStageId(paramUrl) === null) { //nenhuma etapa cadastrada
      return new Promise(resolve => resolve([]));
    }
    if (this.getStageId(paramUrl) !== null) { //existe etapa cadastrada
      return this.stepSelectedStageService.createParamUrlAndBody(this.getId(paramUrl), { stage_id: this.getStageId(paramUrl) }).toPromise();
    }
  }

  /**
   * Busca os feedback da etapa
   * @param paramUrl id do processo seltivo e da etapa
   */
  private getFeedback(paramUrl) {
    if (this.getStageId(paramUrl) === null) {
      return new Promise(resolve => resolve([]));
    }
    if (this.getStageId(paramUrl) !== null) {
      return this.feedbackService.createArray({ stage_id: this.getStageId(paramUrl) }).toPromise();
    }
  }

  /**
   * Busca os campos do sistema
   */
  private getFields() {
    return this.fieldStandardService.getAllFieldsPromise();
  }

  /**
   * Busca os componentes do sistema
   */
  private getComponents() {
    return this.componentStandardService.createArrayNoParam().toPromise();
  }


  /**
   * Busca identidade visual
   */
  private getVisualIdentity() {
    return this.visualIdentityPortalService.read(this.selectiveProcessData.portal_id).toPromise();
  }

  /**
   * Separa o id do PS
   * @param param parâmetros vindos da url
   * @return string
   */
  private getId(param): string {
    if (param.indexOf('-') !== -1) {
      return param.split('-')[0];
    } else {
      return param;
    }
  }

  /**
   * Separa o id da etapa
   * @param param parâmetros vindos da url
   * @return string
   */
  private getStageId(param): string {
    if (param.indexOf('-') !== -1) {
      return param.split('-')[1];
    } else {
      return null;
    }
  }

  /**
   * addStepDataInTheCurrentStage
   * Adiciona os dados do passo na etapa atual
   * @param paramUrl parâmetros vindos da url
   */
  private addStepDataInTheCurrentStage(paramUrl) {
    this.stageData.filter(value => {
      if (value.id === convertToInt(this.getStageId(paramUrl))) {
        value.steps = this.stepData;
      }
    });
    return this.stageData;
  }

  /**
   * addFeedbackDataInTheCurrentStage
   * Adiciona os dados do feedback na etapa atual
   * @param paramUrl parâmetros vindos da url
   */
  private addFeedbackDataInTheCurrentStage(param) {
    this.stageData.filter(value => {
      if (value.id === convertToInt(this.getStageId(param))) {
        value.feedback = this.feedbackData;
      }
    });
    return this.stageData;
  }

  private redirectionStage(data) {
    if (data.length === 1) {
      data.filter(value => {
        if (value.position === 0) {
          this.route.navigate([ `administrativo/processo-seletivo/etapa/${value.selective_process_id}-${value.id}`]);
        }
      });
    }
  }

  /**
   * clearData
   */
  public clearData() {
    this.selectiveProcessData = null;
    this.stageData = null;
    this.stepData = null;
    this.feedbackData = null;
    this.fieldsData = null;
    this.componentsData = null;
    this.visualIdentityData = null;
  }


}

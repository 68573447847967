import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DialogComponent } from '../../dialog.component';
import { SelectiveEditorService } from '@modules/administration/selective-editor/selective-editor.service';
import { SystemSettingService } from '@modules/administration/system-settings/system-setting.service';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-dialog-settings-step',
  templateUrl: './dialog-settings-step.component.html',
  styleUrls: ['./dialog-settings-step.component.scss']
})
export class DialogSettingsStepComponent implements OnInit, AfterViewInit, OnDestroy {

  public contentHeight = {
    height: '100%'
  };

  public definitions = [
    { id: 'style', name: 'Estilo' },
    { id: 'status', name: 'Status' },
    { id: 'logic', name: 'Lógica' },
    { id: 'data', name: 'Fonte de dados' }
  ];

  public selectedTab = { id: 'style', name: 'Estilo' };

  public dataCurrentStage;
  public dataCurrentStep;
  public dataCurrentStepBefore;
  public dataSP;
  public modifiedValue;
  public unsavedStyle;
  public unsavedDataSource;

  @ViewChild('tabGroup', { static: false }) tabs: MatTabGroup;

  constructor(
    public dateComponent: DialogComponent,
    public selectiveEditorService: SelectiveEditorService,
    private systemSettingService: SystemSettingService
  ) {}

  ngOnInit(): void {
    this.dataSP = this.selectiveEditorService.getData();
    this.dataCurrentStage = this.selectiveEditorService.getDataStage();
    this.dataCurrentStep = this.selectiveEditorService.getDataStepID(this.dateComponent.data.step);
    this.dataCurrentStepBefore = JSON.parse(JSON.stringify(this.dataCurrentStep));
  }

  ngAfterViewInit(): void {
    this.systemSettingService.getMatTabGroup(this.tabs);
  }

  tabChange(e) {
    this.selectedTab = this.definitions[e.index];
    if (this.selectedTab.id === 'style') {
      this.contentHeight = {
        height: '100%'
      };
    } else {
      this.contentHeight = {
        height: 'calc(100% - 57px)'
      };
    }
  }

  valueChange(e) {
    if (e.to === 'style') {
      this.unsavedStyle = e.changed;
      if (e.changed) {
        this.dateComponent.data.scheduledClosing = true;
      } else {
        this.dateComponent.data.scheduledClosing = false;
      }
    } else if (e.to === 'dataSource') {
      this.unsavedDataSource = e.changed;
      if (e.changed) {
        this.dateComponent.data.scheduledClosing = true;
        this.systemSettingService.setInterceptTabChange(false);
      } else {
        this.dateComponent.data.scheduledClosing = false;
        this.systemSettingService.setInterceptTabChange(true);
      }
    }
    this.tabs.realignInkBar();
  }

  public save() {
    if (this.dateComponent.data.action === 'add') {
      // chamar serviço de salvar o campo
    } else if (this.dateComponent.data.action === 'update') {
      // chamar serviço de atualizar o campo
    } else if (this.dateComponent.data.action === 'edit') {
      // this.dialogRef.close(this.dateComponent.data);
      // retornar o campo para o editor
    }
  }

  public cancel() {
    this.dateComponent.data.result = false;
    // this.dialogRef.close(this.dataComponent.data);
  }


  ngOnDestroy(): void {
    this.selectiveEditorService.setDataStepID(this.dataCurrentStepBefore);
    this.valueChange({ changed: false, to: 'style', data: null });
    this.valueChange({ changed: false, to: 'dataSource', data: null });
  }


}

import { Injectable } from '@angular/core';
import { NotificationComponent } from './notification.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar) { }

  /**
   * snackBar custom
   * @param message info
   * @param action button name
   * @param type default, success, alert, error
   * @param duration duration hidden
   * @param title title
   */
  notificationOpen(message, action?, type?, duration?, title?) {
    this.snackBar.openFromComponent(NotificationComponent, {
      panelClass: type,
      duration,
      data: {
        title,
        msg: message,
        act: action,
        type
      }
    });
  }
}

<form [formGroup]="formulario" (ngSubmit)="onSubmit()">
  <div class="ps-dialog-config-stage-container">
    <div fxLayout="column" class="ps-row">
      <div class="step-container">
        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Nome do passo</mat-label>
          <input
          maxlength="25"
          #inputTitle
          matInput
          type="text"
          required
          formControlName="name">
          <mat-error *ngIf="f.name.hasError('required')">
            Campo obrigatório
          </mat-error>
          <mat-hint align="end">{{inputTitle.value.length}} / 25</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="column" class="ps-row">
      <app-file-upload
        formControlName="background"
        [configFileUpload]="configBackground"
        [imagePreview]="backgroundPortal"
        [preview]="true"
        [progress]="progress"
        [formControlUpdate]="f.background.value">
      </app-file-upload>
    </div>
  </div>
  <app-save-dialog [form]="formulario"></app-save-dialog>
</form>

<ngx-simplebar
  class="ps-totvs-query-fields-content"
  [options]="options">
  <div fxLayout="row" [ngClass]="{'ps-totvs-query-fields-display': removeRow}">
    <div fxFlex *ngIf="!removeRow">
      <table mat-table [dataSource]="dataSource" class="ps-totvs-query-fields-table" #matTable="matTable">
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>Campo</th>
          <td
            mat-cell
            *matCellDef="let element"
            [title]="element.title ? element.title?.toUpperCase() : element.key?.toUpperCase()">
            {{element.title ? element.title?.toUpperCase() : element.key?.toUpperCase()}}
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> Descrição </th>
          <td mat-cell *matCellDef="let element" [title]="element.description"> {{element.description}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</ngx-simplebar>

<ngx-simplebar
  class="ps-totvs-query-fields-content"
  [options]="options">
  <div fxLayout="row" [ngClass]="{'ps-totvs-query-fields-display': !removeRow}">
    <div fxFlex [ngClass]="{'ps-totvs-query-fields-removeRow': !removeRow}">
      <table
        mat-table
        [dataSource]="dataSource"
        class="ps-totvs-query-fields-table ps-totvs-query-fields-table-removeRow"
        #matTable="matTable">
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>Campo</th>
          <td
            mat-cell
            *matCellDef="let element"
            [title]="element.title ? element.title?.toUpperCase() : element.key?.toUpperCase()">
            {{element.title ? element.title?.toUpperCase() : element.key?.toUpperCase()}}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> Descrição </th>
          <td mat-cell *matCellDef="let element" [title]="element.description"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="remove">
          <th mat-header-cell *matHeaderCellDef="let row"></th>
          <td mat-cell *matCellDef="let element">
            <a
              *ngIf="element.fixed !== 1"
              class="ps-totvs-query-fields-table-btn-removeRow"
              mat-button
              [disabled]=""
              (click)="actionButtonRemove(element)">
              <mat-icon>delete</mat-icon>
            </a>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</ngx-simplebar>

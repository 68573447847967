<form [formGroup]="formulario" (ngSubmit)="onSubmit()" style="height: 100%;">
  <div class="ps-dialog-config-stage-container">
    <div fxLayout="column" class="ps-row">
      <app-file-upload
        formControlName="background"
        [imagePreview]="backgroundPortal"
        [configFileUpload]="configBackground"
        [preview]="true"
        [progress]="progress"
        [formControlUpdate]="f.background.value">
      </app-file-upload>
    </div>
  </div>
  <!-- <app-save-dialog [form]="formulario"></app-save-dialog> -->
</form>

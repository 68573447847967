import { Feedback } from './feedback';

export class FeedbackSerializer {

  fromJson(json: any): Feedback {
    const feed = new Feedback();
    if (typeof json.success !== 'undefined') {
        feed.success = json.success;
        feed.data = this.toJson(json.data);
        return feed;
    } else {
        return this.toJson(json);
    }
  }

  toJson(feed: Feedback): any {
    if (typeof feed === 'object') {
      return {
        id: feed.id,
        position: feed.position,
        stage_id: feed.stage_id,
        background: feed.background,
        conclusive: feed.conclusive,
        icon_type_id: feed.icon_type_id,
        complementary_text: feed.complementary_text,
        cta_text: feed.cta_text,
        content: feed.content,
        standard_components: feed.standard_components,
        standard_fields: feed.standard_fields,
        logics : feed.logics,
        logic_enabled : feed.logic_enabled,
        action_logic_id: feed.action_logic_id,
        condition_logic_id: feed.condition_logic_id,
        changed: 0,
        stage:feed.stage,
        institutional_color: feed.institutional_color
      };
    } else {
      return feed;
    }
  }
}

<div style="height: 104px;margin: 10px 0px;padding: 0 15px;" fxLayout="row" fxLayoutAlign="center center">
    <p class="center">Deseja realmente realizar esta ação ?</p>
</div>
<div fxLayout="row" class="ps-container-dialog-confirm">
    <div fxFlex fxLayoutAlign="end center">
        <button mat-button (click)="onClickCancel()">Cancelar</button>
        <button
            mat-raised-button
            color="primary"
            (click)="onClickConfirmation()">
            Sim
        </button>
    </div>
</div>

import { ValidatorFn, AbstractControl, FormArray, FormGroup } from '@angular/forms';

export class SlideToggleValidator {

  // max length
  public static slideToggle(): ValidatorFn | any {
    return (control: FormGroup) => {
      if (control.parent) {
        const formcontrol: any = control.parent.controls;
        if (!formcontrol.active_paramn.value) {
          return null;
        } else {
          if (formcontrol.parameters.controls.length > 0) {
            return null;
          } else {
            return { slideToggle: true };
          }
        }
      }
    };
  }

  public static slideToggleAndValue(): ValidatorFn | any {
    return (control: FormGroup) => {
      if (control.parent) {
        const formcontrol: any = control.parent.parent.parent.controls;
        console.log(control);
        if (formcontrol.active_paramn.value === null || formcontrol.active_paramn.value === false) {
          console.log('desmarcado');
        } else {
          if (control.value) {
            return null;
          } else {
            console.log('valor vazio e marcado');
            return { slideToggleValue: true };
          }
        }
        // if (!formcontrol.active_paramn.value) {
        //   return null;
        // } else {

          // if (formcontrol.parameters.controls.length > 0) {
          //   return null;
          // } else {
          //   return { slideToggle: true };
          // }
        // }
      }
    };
  }
}

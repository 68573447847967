<form [formGroup]="formulario" style="height: 100%;">
  <div fxFill fxLayout="row">
    <div class="ps-settings-status-feed-left-content" fxFlex="55">
      <div fxLayout="row" class="ps-row-settings-status-feed">
        <div fxFlex>
          <span class="ps-label-radio">Tipo do feedback</span>
          <mat-radio-group color="primary" fxLayoutGap="20px" formControlName="conclusive">
            <mat-radio-button [value]="1">Conclusivo</mat-radio-button>
            <mat-radio-button [value]="0">Não conclusivo</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div
        *ngIf="formulario.controls['conclusive'].value === 1"
        fxLayout="row"
        class="ps-row-settings-status-feed">
        <div fxFlex>
          <span class="ps-label-radio">Ícone</span>
          <div fxLayoutGap="20px">
            <div
              class="ps-choice-of-icon-content"
              [ngClass]="{
                'ps-chosen-of-icon' : formulario.controls['icon_type_id'].value == 1
              }"
              (click)="changeIconType(1)">
              <mat-icon svgIcon="checked"></mat-icon>
              <span>Positivo</span>
            </div>
            <div
              class="ps-choice-of-icon-content"
              [ngClass]="{
                'ps-chosen-of-icon' : formulario.controls['icon_type_id'].value == 2
              }"
              (click)="changeIconType(2)">
              <mat-icon svgIcon="unchecked"></mat-icon>
              <span>Negativo</span>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column">
        <div fxFlex class="ps-row">
          <mat-form-field appearance="fill">
            <mat-label>Texto complementar</mat-label>
            <input
             matInput #input1 maxlength="25"
             formControlName="complementary_text">
            <mat-hint align="end">{{input1.value?.length || 0}}/25</mat-hint>
          </mat-form-field>
        </div>
        <div fxFlex>
          <mat-form-field appearance="fill">
            <mat-label>Texto do CTA</mat-label>
            <input
              matInput #input2 maxlength="20"
              formControlName="cta_text">
            <mat-hint align="end">{{input2.value?.length || 0}}/20</mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="ps-settings-status-feed-right-content" fxFlex>
      <span class="ps-label-radio">Pré-visualização</span>
      <div
        class="ps-settings-status-feed-preview-content" fxLayout="row"
        [ngClass]="{
          'not-conclusive-preview' : formulario.controls['conclusive'].value == 0,
          'negative-preview' : formulario.controls['conclusive'].value != 0 && formulario.controls['icon_type_id'].value == 2
        }"
        [ngStyle]="{
          'background-color': formulario.controls['conclusive'].value == 1 && formulario.controls['icon_type_id'].value == 1 ?  item.institutional_color : '#fff'
        }">
        <div class="ps-settings-status-feed-preview-icon" fxFlex>
          <i
            class="material-icons"
            *ngIf="formulario.controls['icon_type_id'].value == 1 && formulario.controls['conclusive'].value === 1">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.7919 38.9582C9.19974 38.9582 0.570312 30.3288 0.570312 19.7027C0.570312 9.11051 9.19974 0.481079 19.7919 0.481079C30.3842 0.481079 39.0136 9.11051 39.0136 19.7027C39.0474 30.3288 30.418 38.9582 19.7919 38.9582ZM19.7919 2.41001C10.2488 2.41001 2.4654 10.1934 2.4654 19.7366C2.4654 29.2797 10.2488 37.0631 19.7919 37.0631C29.3351 37.0293 37.1185 29.2459 37.1185 19.7027C37.1185 10.1596 29.3351 2.41001 19.7919 2.41001Z"
                [ngStyle]="{'fill': '#fff'}" />
              />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M16.2059 24.0686L11.502 19.3647L9.94531 20.9552L16.2059 27.2158L29.6746 13.7809L28.084 12.2242L16.2059 24.0686Z"
                [ngStyle]="{'fill': '#fff'}" />
              />
            </svg>
          </i>
          <i
            class="material-icons"
            *ngIf="formulario.controls['icon_type_id'].value == 2 && formulario.controls['conclusive'].value === 1">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.5419 38.9582C8.94974 38.9582 0.320312 30.3288 0.320312 19.7027C0.320312 9.11051 8.94974 0.481079 19.5419 0.481079C30.1342 0.481079 38.7636 9.11051 38.7636 19.7027C38.7974 30.3288 30.168 38.9582 19.5419 38.9582ZM19.5419 2.41001C9.99881 2.41001 2.2154 10.1934 2.2154 19.7366C2.2154 29.2797 9.99881 37.0631 19.5419 37.0631C29.0851 37.0293 36.8685 29.2459 36.8685 19.7027C36.8685 10.1596 29.0851 2.41001 19.5419 2.41001Z"
                [ngStyle]="{'fill': item.institutional_color}" />
              />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M19.5448 18.1357L13.6235 12.1973L12.0475 13.7733L17.9677 19.7083L12.0195 25.6395L13.5955 27.2154L19.5406 21.2852L25.4824 27.2419L27.0391 25.6514L21.1172 19.7126L27.0641 13.7806L25.4736 12.2239L19.5448 18.1357Z"
                [ngStyle]="{'fill': item.institutional_color}" />
              />
            </svg>
          </i>
          <i
            *ngIf="formulario.controls['conclusive'].value === 0"
            class="material-icons ps-settings-status-feed-preview-icon-not-conclusive">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M19.8486 0.622803C9.25638 0.622803 0.626953 9.25224 0.626953 19.8444C0.626953 30.4366 9.25638 39.0661 19.8486 39.0661C30.4408 39.0661 39.0702 30.4366 39.0702 19.8444C39.1041 9.25224 30.4746 0.622803 19.8486 0.622803ZM19.8486 37.171C10.3055 37.171 2.55588 29.3876 2.55588 19.8444C2.55588 10.3013 10.3393 2.51789 19.8824 2.51789C29.4256 2.51789 37.209 10.3013 37.209 19.8444C37.1751 29.3876 29.3917 37.171 19.8486 37.171Z"
                [ngStyle]="{'fill': item.institutional_color}" />
              <path
                d="M21.5382 10.2336H18.4248V23.0932H21.5382V10.2336Z"
                [ngStyle]="{'fill': item.institutional_color}"
              />
              <path
                d="M21.5382 26.511H18.4248V29.489H21.5382V26.511Z"
                [ngStyle]="{'fill': item.institutional_color}"
              />
            </svg>
          </i>
        </div>
        <div fxFlex="60" class="ps-settings-status-feed-preview-text">
          <span class="ps-title-large">{{item.stage}}</span>
          <span class="ps-description-14">{{formulario.controls['complementary_text'].value}}</span>
        </div>
        <div *ngIf="formulario.controls['cta_text'].value" class="ps-settings-status-feed-preview-button" fxFlex>
          <button mat-stroked-button *ngIf="formulario.controls['conclusive'].value != 0 && formulario.controls['icon_type_id'].value == 1">{{formulario.controls['cta_text'].value}}</button>
          <button mat-raised-button  
          *ngIf="formulario.controls['conclusive'].value == 0 || 
            (formulario.controls['conclusive'].value != 0 && formulario.controls['icon_type_id'].value == 2)">
            {{formulario.controls['cta_text'].value}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <app-save-dialog [form]="formulario"></app-save-dialog> -->
</form>

import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { NotificationComponent } from '../notification/notification.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public loaderControl = new BehaviorSubject(false);

  constructor(private snackBar: MatSnackBar) { }


  getChangesLoaderState(): Observable<any> {
    return this.loaderControl.asObservable();
  }

  /**
   * name
   * @param type default, success, alert, error
   */
  public setChangesLoaderState(value: boolean, message?: string, action?: string, type = 'default', title?: string, duration = 4000) {
    this.loaderControl.next(value);
    if (value === false && typeof message !== 'undefined' && typeof action !== 'undefined') {
      setTimeout(() => {
        this.snackBar.openFromComponent(NotificationComponent, {
          panelClass: type,
          duration,
          data: {
            title,
            msg: message,
            act: action,
            type
          }
        });
      }, 500);
    }
  }
}

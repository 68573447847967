import { Component, ElementRef, HostListener, Input, OnInit, OnChanges, Injector, SimpleChange, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env';
import { NotificationService } from '../notification/notification.service';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ],
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnChanges, ControlValueAccessor {
  @Input() progress;
  @Input() formControlUpdate;
  @Input() configFileUpload;
  @Input() preview = false;
  @Input() imagePreview = false;
  // tslint:disable-next-line: ban-types
  onChange: Function;
  public file: any | null = null;
  public configApp: any;
  public imgUploud: any;
  public imgDefault: any;
  public imgName: any;

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    if (file !== null) {
      if (this.fileTypeValidator(file) && this.fileSizeValidator(file)) {
        this.file = file;
        this.onChange(file);
      }
    }
  }

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private injector: Injector,
    public domSanitizer: DomSanitizer,
    private notification: NotificationService) {
    this.configApp = environment;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof this.formControlUpdate !== 'undefined' && this.formControlUpdate !== null && this.formControlUpdate !== '') {
      this.file = this.formControlUpdate;
      if (typeof this.formControlUpdate === 'string') {
        const split = this.formControlUpdate.split('/');
        this.imgName = split.reverse()[0];
        this.imgDefault = this.imgName.indexOf('default') !== -1;
        this.imgUploud = this.configApp.storage_path + this.formControlUpdate;
      } else if (typeof this.formControlUpdate === 'object') {
        const split = this.formControlUpdate.name.split('.');
        this.imgName = split.reverse()[0];
        this.imgDefault = this.imgName.indexOf('default') !== -1;
        this.imgUploud = this.formControlUpdate;
      }

      // Preview
      if (typeof changes.formControlUpdate !== 'undefined') {
        if (this.preview && this.file !== null && typeof changes.formControlUpdate.currentValue === 'object') {
          const reader = new FileReader();
          reader.readAsDataURL(this.file);
          reader.onload = () => {
            this.imgUploud = this.domSanitizer.bypassSecurityTrustUrl(String(reader.result));
          };
        }
      }
    }
  }

  writeValue(e) {
    // clear file input
    if (e === true) {
      this.file = null;
      this.onChange(this.file);
      this.formControlUpdate = null;
      this.imgUploud = undefined;
      const document: any = this.host.nativeElement.getElementsByClassName('file-input').item(0);
      document.value = null;
    }
  }

  // tslint:disable-next-line: ban-types
  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  // tslint:disable-next-line: ban-types
  registerOnTouched(fn: Function) {
  }

  private fileTypeValidator(file) {
    if (file.type && file.type !== '') {
      const typeArray = this.configFileUpload.acceptedType.split(',');
      const extension = file.type.split('/')[1].toLowerCase();
      if (typeof typeArray.find(x => x.toLowerCase().trim() === extension.toLowerCase()) === 'undefined') {
        this.notification.notificationOpen(this.configFileUpload.msgTypeError, 'ok', 'alert', 4000);
        return false;
      }
      return true;
    } else {
      this.notification.notificationOpen('Arquivo inválido, tente outro.', 'ok', 'alert', 4000);
      return false;
    }
  }

  private fileSizeValidator(file) {
    if (file.size) {
      if (file.size > this.configFileUpload.acceptedSize) {
        this.notification.notificationOpen(this.configFileUpload.msgSizeError, 'ok', 'alert', 4000);
        return false;
      }
      return true;
    } else {
      this.notification.notificationOpen('Arquivo inválido, tente outro.', 'ok', 'alert', 4000);
      return false;
    }
  }

}

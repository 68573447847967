import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageNotAccessService {

  private changesMade: boolean;

  constructor() { }

  public makeChanges(param) {
    this.changesMade = param;
  }

  public getChange() {
    return this.changesMade;
  }
}

import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientLoaderService {

  public loaderControl = new BehaviorSubject(false);
  public color: string;
  public loadAvailability = new BehaviorSubject(true);;

  constructor() { }


  getChangesLoaderState(): Observable<any> {
    return this.loaderControl.asObservable();
  }

  setChangesLoaderState(value: any) {
    if (this.loadAvailability.getValue()) {
      this.loaderControl.next(value);
    } else {
      this.loaderControl.next(false);
    }
  }

  getLoadAvailability(): boolean {
    return this.loadAvailability.getValue();
  }

  setLoadAvailability(value: any) {
    this.loadAvailability.next(value);
  }

  setColor(param) {
    this.color = param;
  }
}

import { VisualIdentityPortal } from './visual-identity-portal';

export class VisualIdentityPortalSerializer {

  fromJson(json: any): VisualIdentityPortal {
    const vi = new VisualIdentityPortal();
    if (typeof json.success !== 'undefined' && typeof json.data !== 'undefined') {
      vi.success = json.success;
      vi.data = json.data;
      return vi;
    } else {
      vi.id = json.id;
      // vi.client_id = json.client_id;
      vi.logo = json.logo;
      vi.institutional_color = json.institutional_color;
      vi.favicon = json.favicon;
      vi.background = json.background;
      vi.header_message_active = json.header_message_active;
      vi.header_message = json.header_message;
      return vi;
    }
  }

  toJson(user: VisualIdentityPortal): any {
    return {
      id: user.id,
      logo: user.logo,
      institutional_color: user.institutional_color,
      favicon: user.favicon,
      background: user.background,
      header_message_active: user.header_message_active,
      header_message: user.header_message
    };
  }
}

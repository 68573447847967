import { Injectable, EventEmitter } from '@angular/core';
import { DialogComponent } from '../../dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmationComponent } from './dialog-confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class DialogConfirmationService {

  private dadosComponent: any;

  clickedSubmit = new EventEmitter();

  constructor(private dialog: MatDialog) { }

  setDadosDialogConfirm(dados: object) {
    this.dadosComponent = dados;
  }

  getDadosDialogConfirm() {
    return this.dadosComponent;
  }

  openDialog(component, clickedSubmit?) {
    this.dialog.open(DialogComponent, {
      maxWidth: '60vw',
      width: '360px',
      minHeight: '210px',
      data: {
        titleDialog: this.dadosComponent.titleDialogConfirm,
        subTitleDialog: this.dadosComponent.subTitleDialogConfirm,
        component,
        data: {
          action: 'confirm'
        },
        click: clickedSubmit
      },
      autoFocus: false,
      disableClose: this.dadosComponent.disableCloseDialogConfirm
    });
  }

}

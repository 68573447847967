import { ResourceService } from '../../../http/resource/resource.service';
import { HttpClient } from '@angular/common/http';
import { FeedbackSerializer } from './feedback.serializer';
import { environment } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Feedback } from './feedback';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends ResourceService<Feedback> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      `${environment.API}`,
      'selective-process/feedback',
      new FeedbackSerializer());
  }
}

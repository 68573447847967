<mat-dialog-actions class="ps-dialog-container-actions">
  <div fxLayout="row">
    <div fxFlex="100">
      <mat-divider></mat-divider>
    </div>
  </div>
  <div fxLayout="row" fxFill style='margin-top: 2px;'>
    <div *ngIf="sliderFooter" fxFlex fxLayoutAlign="start center" class="ps-dialog-content-actions">
      <mat-slide-toggle color="primary" [formControl]="sliderFooter.formControl">{{sliderFooter.text}}</mat-slide-toggle>
    </div>
    <div fxFlex fxLayoutAlign="end center" class="ps-dialog-content-actions">
      <a
        mat-stroked-button
        (click)="onNoClick()">
        Cancelar
      </a>
      <button
        *ngIf="clickedSubmit.observers.length === 0 && form !== undefined"
        mat-raised-button
        color="primary"
        [disabled]="!form?.valid"
        type="submit">
        {{ dados.titleButton }}
      </button>
      <button
        *ngIf="clickedSubmit.observers.length === 0 && form === undefined"
        mat-raised-button
        color="primary"
        [disabled]="noForm.length < 0"
        type="submit">
        {{ dados.titleButton }}
      </button>
      <a
        *ngIf="clickedSubmit.observers.length > 0"
        mat-raised-button
        color="primary"
        [disabled]="!form.valid || !form.dirty"
        (click)="onClickConfirm('confirm', {})">
        {{ dados.titleButton }}
      </a>
    </div>
  </div>
</mat-dialog-actions>

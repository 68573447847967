import { Resource } from '../../../http/resource/resource.model';

export class Step extends Resource {
  // id is inherited from Resource
  // tslint:disable: variable-name
  stage_id: number;
  position: number;
  name: string;
  background: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  content: Array<any>;
  standard_fields: Array<any>;
  standard_components: Array<any>;

  changed: number;
}

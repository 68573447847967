import { Resource } from '../../../http/resource/resource.model';
// tslint:disable: variable-name

export class Fields extends Resource {
  // id is inherited from Resource
  field_id: number;
  field_settings_id: number;
  key: string;
  label: string;
  step_id: number;
  description: number;
  condition_logic_id: number;
  form_build: any;
  logic_enabled: any;
  action_logic_id: any;
  totvs_table: string;
  totvs_field: string;
  totvs_alternative_name?: string;
  rubeus_table: string;
  rubeus_field: string;
  column_index: any;
  column_id: any;
  column_size: any;
  data_type: number;
  form_position: number;
  field_type_id: number;
  read_only: number;
  ps_table: number;
  data_value: string;
  relation_id?: number;
  logics?: Array<any>;
  display_table?: Array<any>;
  field_relation?: Array<any>;
  predefined_source_options: number;
  defined_source: number;
  type: string;
  multivalued: any;
  predefined_options: any;
  // external_source: any;
}

import { Resource } from '../../../http/resource/resource.model';
// tslint:disable: variable-name

export class Component extends Resource {
  // id is inherited from Resource
  component_id: number;
  name: string;
  sub_component_id: number;
  form_build: any;
  sub_component_type: any;
}

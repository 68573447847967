<div fxLayout="row" fxLayoutAlign="center center" *ngIf="data.type === 'default'">
  <div fxFlex="100">
    <div fxLayoutAlign="start center" *ngIf="(data?.title | typeof) !== 'undefined'">
        <span class="ps-notification-title">{{data.title}}</span>
    </div>
    <span class="ps-notification-text" fxLayoutAlign="start center">
        {{ data.msg }}
    </span>
  </div>
    <div fxFlex fxLayoutAlign="center center" *ngIf="data.act !== ''" fxFill class="ps-notification-button-content">
        <button mat-button class="ps-notification-button" (click)="closeNotification()">{{ data.act }}</button>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="center center" class="success" *ngIf="data.type === 'success'">
    <div fxFlex>
        <img src="../../../../assets/images/icons/check.svg" alt="sucesso" class="ps-notification-icon">
    </div>
    <div fxFlex="100">
        <div fxLayoutAlign="start center" *ngIf="(data?.title | typeof) !== 'undefined'">
            <span class="ps-notification-title">{{data.title}}</span>
        </div>
        <span class="ps-notification-text" fxLayoutAlign="start center">
            {{ data.msg }}
        </span>
    </div>
    <div fxFlex fxLayoutAlign="center center" *ngIf="data.act !== ''" fxFill class="ps-notification-button-content">
        <button mat-button class="ps-notification-button" (click)="closeNotification()">{{ data.act }}</button>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="center center" class="alert" *ngIf="data.type === 'alert'">
    <div fxFlex>
        <img src="../../../../assets/images/icons/alert.svg" alt="atenção" class="ps-notification-icon">
    </div>
    <div fxFlex="100">
        <div fxLayoutAlign="start center" *ngIf="(data?.title | typeof) !== 'undefined'">
            <span class="ps-notification-title">{{data.title}}</span>
        </div>
        <span class="ps-notification-text" fxLayoutAlign="start center">
            {{ data.msg }}
        </span>
    </div>
    <div fxFlex fxLayoutAlign="center center" *ngIf="data.act !== ''" fxFill class="ps-notification-button-content">
        <button mat-button class="ps-notification-button" (click)="closeNotification()">{{ data.act }}</button>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="center center" class="error" *ngIf="data.type === 'error'">
    <div fxFlex>
        <img src="../../../../assets/images/icons/error.svg" alt="error" class="ps-notification-icon">
    </div>
    <div fxFlex="100">
        <div fxLayoutAlign="start center"  *ngIf="(data?.title | typeof) !== 'undefined'">
            <span class="ps-notification-title">{{data.title}}</span>
        </div>
        <span class="ps-notification-text" fxLayoutAlign="start center">
            {{ data.msg }}
        </span>
    </div>
    <div fxFlex fxLayoutAlign="center center" *ngIf="data.act !== ''" fxFill class="ps-notification-button-content">
        <button mat-button class="ps-notification-button" (click)="closeNotification()">{{ data.act }}</button>
    </div>
</div>
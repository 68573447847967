import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog.component';
import { DialogConfirmationComponent } from '../template/dialog-confirmation/dialog-confirmation.component';
import { DialogConfirmationService } from '../template/dialog-confirmation/dialog-confirmation.service';
import { ConfirmComponent } from '@shared/components/confirm/confirm.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-save-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: ['./save-dialog.component.scss']
})
export class SaveDialogComponent implements OnInit {

  @Input() form;
  @Output() clickedSubmit = new EventEmitter();
  @Input() titleDialogConfirm?: string;
  @Input() subTitleDialogConfirm?: string;
  @Input() disableCloseDialogConfirm?: boolean;
  @Input() noForm = [];
  @Input() sliderFooter?: {
    formControl: FormControl,
    text: string
  };

  @Input() scheduledClosing: any;

  dados: any;

  constructor(
    public dialogRef: MatDialogRef<SaveDialogComponent>,
    private dialog: MatDialog,
    private serviceDialogConfirm: DialogConfirmationService) {
    this.dados = dialogRef.componentInstance.dados;
  }

  ngOnInit() {}

  onNoClick(): void {
    if (typeof this.dados.scheduledClosing !== 'undefined') {
      if (this.dados.scheduledClosing) {
        this.dialog.open(ConfirmComponent, {
          maxWidth: '60vw',
          width: '325px',
          minHeight: '210px',
          autoFocus: false,
          data: {
            title: 'Fechar configurações?',
            message: 'As alterações feitas não serão salvas.',
            btnOkText: 'Sair',
            btnCancelText: 'Cancelar',
            reverseBtn: true
          },
          disableClose: false
        }).afterClosed().subscribe(success => {
          if (success) {
            this.dialogRef.close({ event: null, data: this.dados });
          }
        });
      } else {
        this.dialogRef.close({ event: null, data: this.dados });
      }
    } else {
      this.dialogRef.close({ event: null, data: this.dados });
    }
  }

  // tenho que tirar isso daqui
  // E porque não tira de uma vez então?
  // esse comentario foi a 8 meses e eu nao me lembro nem oq almocei ontem
  onClickConfirm() {
    this.serviceDialogConfirm.openDialog(DialogConfirmationComponent, this.clickedSubmit);
  }
}

import { Stage } from './stage';

export class StageSerializer {

  fromJson(json: any): Stage {
    const stage = new Stage();
    if (typeof json.success !== 'undefined') {
      stage.success = json.success;
      stage.data = this.toJson(json.data);
      return stage;
    } else {
      return this.toJson(json);
    }
  }

  toJson(stage: Stage): any {
    if (typeof stage === 'object') {
      if (typeof stage.steps === 'undefined') {
        return {
          id: stage.id,
          stage_id: stage.id,
          selective_process_id: stage.selective_process_id,
          title: stage.title,
          status: stage.status,
          position: stage.position,
          action_logic_id: stage.action_logic_id,
          condition_logic_id: stage.condition_logic_id,
          query: stage.query,
          logics: stage.logics,
          logic_enabled: stage.logic_enabled,
          stages_positions: stage.stages_positions
        };
      } else {
        return {
          id: stage.id,
          stage_id: stage.id,
          selective_process_id: stage.selective_process_id,
          title: stage.title,
          status: stage.status,
          position: stage.position,
          steps: stage.steps,
          query: stage.query,
          action_logic_id: stage.action_logic_id,
          condition_logic_id: stage.condition_logic_id,
          logics: stage.logics,
          logic_enabled: stage.logic_enabled,
          stages_positions: stage.stages_positions
        };
      }
    } else {
      return stage;
    }
  }
}

import { Fields } from './fields';

export class FieldsSerializer {

  fromJson(json: any): Fields {
    const sf = new Fields();
    if (typeof json.success !== 'undefined') {
        sf.success = json.success;
        sf.data = this.toJson(json.data);
        return sf;
    } else {
        return this.toJson(json);
    }
  }

  toJson(sf: Fields): any {
    if (typeof sf === 'object') {
      return {
        id: sf.id,
        field_id: sf.field_id,
        field_settings_id: sf.field_settings_id,
        key: sf.key,
        label: sf.label,
        step_id: sf.step_id,
        description: sf.description,
        form_build: sf.form_build,
        totvs_table: sf.totvs_table,
        totvs_field: sf.totvs_field,
        totvs_alternative_name: sf.totvs_alternative_name,
        rubeus_table: sf.rubeus_table,
        rubeus_field: sf.rubeus_field,
        column_index: sf.column_index,
        column_id: sf.column_id,
        column_size: sf.column_size,
        data_type: sf.data_type,
        read_only: sf.read_only,
        data_value: sf.data_value,
        relation_id: sf.relation_id,
        logics: sf.logics,
        display_table: sf.display_table,
        field_relation: sf.field_relation,
        predefined_source_options: sf.predefined_source_options,
        defined_source: sf.defined_source,
        type: sf.type,
        condition_logic_id: sf.condition_logic_id,
        logic_enabled: sf.logic_enabled,
        action_logic_id:sf.action_logic_id,
        form_position: sf.form_position,
        field_type_id: sf.field_type_id,
        ps_table: sf.ps_table,
        multivalued: sf.multivalued,
        predefined_options: sf.predefined_options
        // external_source: sf.external_source
      };
    } else {
      return sf;
    }
  }
}

import { Component, OnInit, DoCheck, ChangeDetectorRef } from '@angular/core';
import { ClientLoaderService } from './client-loader.service';
import { HTTPStatus } from '@app/clientHttp/http.interceptor';

@Component({
  selector: 'app-client-loader',
  templateUrl: './client-loader.component.html',
  styleUrls: ['./client-loader.component.scss'],
})
export class ClientLoaderComponent implements OnInit, DoCheck {
  // tslint:disable: deprecation
  public activeLoading = true;
  public controlLoaderChanges: any;
  public color;

  constructor(
    public clientLoaderService: ClientLoaderService,
    private httpStatus: HTTPStatus,
    private cdr: ChangeDetectorRef
  ) {
    this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      if (this.clientLoaderService.getLoadAvailability()) {
        this.activeLoading = status;
      } else {
        this.activeLoading = false;
      }
    });
    setInterval(() => {this.activeLoading = this.activeLoading;}, 1000);
  }

  ngOnInit() {
    this.controlLoaderChanges = this.clientLoaderService.getChangesLoaderState()
    .subscribe(
      value => {
        this.activeLoading = value;
        this.cdr.detectChanges();
      }

    );
  }

  ngDoCheck(): void {
    if (this.clientLoaderService.color) {
      this.color = this.clientLoaderService.color;
    }
  }

  get spinnerStyle() { return { color: this.color}; }
}

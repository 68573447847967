import { ResourceService } from '../../../http/resource/resource.service';
import { HttpClient } from '@angular/common/http';
import { Step } from './step';
import { environment } from '@env';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StepConfigSerializer } from './step-config.serializer';

@Injectable({
  providedIn: 'root'
})
export class StepConfigService extends ResourceService<Step> {
  constructor(httpClient: HttpClient, router: Router) {
    super(
      httpClient,
      `${environment.API}`,
      `step/step-config`,
      new StepConfigSerializer());
  }
}

import { Resource } from '../../../http/resource/resource.model';

export class VisualIdentityPortal extends Resource {
  // id is inherited from Resource
  logo: string;
  // tslint:disable-next-line: variable-name
  institutional_color: string;
  // tslint:disable-next-line: variable-name
  // cascade_change: number;cascade_change
  favicon: string;
  background: string;
  scopeChangeSettings: string;
  header_message_active:number;
  header_message:string;
  changed_by:string;
  last_change:Date;
}

import { Injectable } from '@angular/core';
import { MatTabGroup, MatTab, MatTabHeader } from '@angular/material/tabs';
import { ConfirmService } from '@shared/components/confirm/confirm.service';
import { DiffObjects } from '@shared/utils/util';

@Injectable({
  providedIn: 'root',
})
export class DataSourceStepFeedbackModel {

  public tabs;
  public interceptValue = true;
  public valueOne;
  public valueTwo;
  public modified;

  constructor(private confirmService: ConfirmService) {
  }

  getMatTabGroup(tabs: MatTabGroup) {
    this.tabs = tabs;
    this.tabs._handleClick = this.interceptTabChange.bind(this);
  }

  interceptTabChange(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
    if (this.status()) {
      this.confirmService
        .openConfirm(
          'Sair da página ?',
          'As alterações feitas não serão salvas.',
          'Sair',
          'Cancelar',
          true
        )
        .afterClosed()
        .subscribe(success => {
          return success && MatTabGroup.prototype._handleClick.apply(this.tabs, [tab, tabHeader, idx]);
        });
    } else {
      return this.interceptValue && MatTabGroup.prototype._handleClick.apply(this.tabs, [tab, tabHeader, idx]);
    }
  }

  setInterceptTabChange(e) {
    this.interceptValue = e;
  }

  status() {
    return this.modified = typeof DiffObjects(
      [this.valueOne],
      [typeof this.valueTwo === 'undefined' ? this.valueOne : this.valueTwo]
    ) === 'undefined' ? false : true;
  }
}

import { Resource } from '../../http/resource/resource.model';
// tslint:disable: variable-name

export class SystemQueries extends Resource {
  id: number;
  title: string;
  code: string;
  colligate: string;
  use_cache: number;
  cache_interval_type_id: number;
  system: string;
  client_id: number;
  portal_id: number;
  type_id: number;
  local_id: number;
  component_id: number;
  field_settings_id: number;
  selective_process_id: number;
  stage_id: number;
  step_id: number;
  feedback_id: number;
  status: number;
  status_title: string;
  updated_at: string;
  fields: any[];
  fields_query: any[];
  parameters: any[];
  context: any[];
  cascade_change: any;
  description: string;
  lazy_load: number;
}

import { ResourceService } from '../../../http/resource/resource.service';
import { HttpClient } from '@angular/common/http';
import { ComponentSerializer } from './component.serializer';
import { environment } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Component } from './component';

@Injectable({
  providedIn: 'root'
})
export class ComponentStandardService extends ResourceService<any> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      `${environment.API}`,
      'selective-process/components',
      new ComponentSerializer());
  }
}

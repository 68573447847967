import { Resource } from '../../http/resource/resource.model';

export class SelectiveProcesses extends Resource {
  // id is inherited from Resource
  // tslint:disable: variable-name
  portal_id: number;
  name: string;
  identifier: string;
  unit: string;
  unit_identifier: string;
  header_message: string;
  status: number;
  edited_by: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
}

import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  ComponentFactoryResolver,
  OnDestroy,
  Optional
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef  } from '@angular/material/dialog';
import { ConfirmComponent } from '../confirm/confirm.component';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy {

  @ViewChild('target', { read: ViewContainerRef, static: true }) vcRef: ViewContainerRef;

  componentRef: ComponentRef<any>;

  public dados?: any;
  public action?: string;
  public closeButton: boolean;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogComponent>,
    private resolver: ComponentFactoryResolver,
    private dialog: MatDialog
  ) {
    this.dados = data;
    if (typeof this.dados.data !== 'undefined') {
      this.action = this.dados.data.action;
    }
  }

  ngOnInit() {
    if (this.dados.closeButton === undefined) {
      this.closeButton = true;
    } else {
      this.closeButton = this.dados.closeButton;
    }

    const factory = this.resolver.resolveComponentFactory(this.data.component);
    this.componentRef = this.vcRef.createComponent(factory);
  }

  onNoClick(): void {
    if (typeof this.dados.changed !== 'undefined' || typeof this.dados.scheduledClosing !== 'undefined') {
      let obj;
      if (this.dados.changed) {
        obj = this.dados.changed.find(o => o.alt === true);
      }
      if (typeof obj !== 'undefined' || this.dados.scheduledClosing) {
        this.dialog.open(ConfirmComponent, {
          maxWidth: '60vw',
          width: '325px',
          minHeight: '210px',
          autoFocus: false,
          data: {
            title: 'Fechar configurações?',
            message: 'As alterações feitas não serão salvas.',
            btnOkText: 'Sair',
            btnCancelText: 'Cancelar',
            reverseBtn: true
          },
          disableClose: false
        }).afterClosed().subscribe(success => {
          if (success) {
            this.dialogRef.close({ event: this.action, data: this.dados });
          }
        });
      } else {
        this.dialogRef.close({ event: this.action, data: this.dados });
      }
    } else {
      this.dialogRef.close({ event: this.action, data: this.dados });
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.dialogRef.close({ event: this.action, data: this.dados });
    }
  }

}

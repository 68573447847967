export interface QueryBuilder {
  toQueryMap: () => Map<string, string>;
  // toQueryString: (param) => string;
}

export class QueryOptions implements QueryBuilder {

  public pageNumber: number;
  public pageSize: number;

  constructor() {
    this.pageNumber = 1;
    this.pageSize = 10000;
  }

  public static toQueryString(paramsObject: any): string {
    return Object
      .keys(paramsObject)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
      .join('&');
  }

  public toQueryMap() {
    const queryMap = new Map<string, string>();
    queryMap.set('pageNumber', `${this.pageNumber}`);
    queryMap.set('pageSize', `${this.pageSize}`);

    return queryMap;
  }
}

<div class="content-dialog-step">
  <mat-tab-group #tabGroup="matTabGroup" [animationDuration]="'0ms'" class="ps-dialog-config-step-matgroup"
    (selectedTabChange)="tabChange($event)">
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Estilo</span>
        <button
          [ngClass]="{'ps-sp-tabs-step-tooltip-isActive pulse-sp-step-tabs': unsavedStyle}"
          mat-icon-button
          class="ps-sp-tabs-step-tooltip"
          matTooltip="Alterações não foram salvas"
          matTooltipPosition="after"
          matTooltipClass="ps-tooltip-content">
          <mat-icon svgIcon="alert"></mat-icon>
        </button>
      </ng-template>
      <ng-template matTabContent>
        <div class="ps-dialog-config-step-container">
          <app-style-setting-step
            (valueChanged)="valueChange($event)"
            [dataCurrentStep]="dataCurrentStep"
            [dataCurrentStage]="dataCurrentStage"
            [dataSP]="dataSP">
          </app-style-setting-step>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab #currentTab>
      <ng-template mat-tab-label>
        <span>Fonte de dados</span>
        <button
          [ngClass]="{'ps-sp-tabs-step-tooltip-isActive pulse-sp-step-tabs': unsavedDataSource}"
          mat-icon-button
          class="ps-sp-tabs-step-tooltip"
          matTooltip="Alterações não foram salvas"
          matTooltipPosition="after"
          matTooltipClass="ps-tooltip-content">
          <mat-icon svgIcon="alert"></mat-icon>
        </button>
      </ng-template>
      <ng-template matTabContent>
        <div style="height: 100%;">
          <app-data-source-setting-step
            (valueChanged)="valueChange($event)"
            [dataSourceStep]="dateComponent"
            [tab]="currentTab.isActive">
          </app-data-source-setting-step>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="footer-dialog-step" *ngIf="selectedTab.id != 'style'">
  <mat-divider style="width: 100%;"></mat-divider>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="21px">
    <button mat-stroked-button (click)="cancel()">
      Cancelar
    </button>
    <button mat-stroked-button (click)="save()" color="primary">
      Salvar
    </button>
  </mat-dialog-actions>
</div>

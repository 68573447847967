<div fxLayout="column" fxFlex="100" *ngIf="preview">
  <div fxLayout="row" class="row">
    <div fxFlex="30">
      <p class="ps-title-large ps-title-type-img">{{configFileUpload?.title}}</p>
      <div class="ps-file-img-upload-logo" *ngIf="configFileUpload?.type === 'logo'">
        <img [src]="imgUploud" *ngIf="imgUploud">
        <img src="{{configApp.logoInstitucional}}" *ngIf="!imgUploud">
      </div>
      <div class="ps-file-img-upload-favIcon" *ngIf="configFileUpload?.type === 'favIcon'">
        <img [src]="imgUploud" *ngIf="imgUploud !== undefined">
        <img src="{{configApp.favIcon}}" *ngIf="imgUploud === undefined">
      </div>
      <div class="ps-file-img-upload-background" *ngIf="configFileUpload?.type === 'background'">
        <img [src]="imgUploud" *ngIf="imgUploud">
        <img src="{{configApp.storage_path + imagePreview}}" *ngIf="!imgUploud && imagePreview">
        <img src="{{configApp.backgroundClient}}" *ngIf="!imgUploud && !imagePreview">
      </div>
    </div>
    <div fxFlex="70" class="ps-file-content-action">
      <div fxLayout="column">
        <div fxFlex="100" style="position: relative;">
          <div class="ps-file-field">
            <div class="ps-btn-file">
              <span>{{configFileUpload?.buttonAdd}}</span>
              <!-- <span *ngIf="file !== null || formControlUpdate">{{configFileUpload?.buttonAdd}}</span> -->
              <input type="file" class="file-input">
            </div>
          </div>
          <div *ngIf="!imgDefault" style="display: inline;">
            <a *ngIf="file !== null || formControlUpdate !== null" mat-stroked-button class="ps-file-btn-remove"
              (click)="writeValue(true)">
              <span>{{configFileUpload?.buttonRemove}}</span>
            </a>
          </div>
        </div>
        <div fxFlex="100">
          <p class="ps-reading-15 ps-file-text-explanatory" [innerHtml]="configFileUpload?.textExplanatory"></p>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!preview">
  <div fxLayout="row">
    <div fxFlex>
    <h2 class="ps-title-large ps-title-margin">{{configFileUpload?.title}}</h2>
    </div>
  </div>
  <div fxLayout="row" *ngIf="file !== null || formControlUpdate" class="ps-file-container-name">
    <div fxFlex>
      <mat-chip-list>
        <mat-chip *ngIf="(file | typeof) === 'object'">{{ file.name }}</mat-chip>
        <mat-chip *ngIf="(file | typeof) === 'string'">{{ imgName }}</mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center">
    <div fxFlex style="position: relative;">
      <div class="ps-file-field">
        <div class="ps-btn-file">
          <span *ngIf="file === null && formControlUpdate === ''">Procurar em meu computador</span>
          <span *ngIf="formControlUpdate !== ''">Alterar arquivo</span>
          <input type="file" class="file-input">
        </div>
      </div>
      <a *ngIf="file !== null || formControlUpdate" mat-stroked-button class="ps-file-btn-remove"
        (click)="writeValue(true)">
        <span>Remover</span>
      </a>
    </div>
  </div>

  <div fxLayout="row">
    <div fxFlex>
      <p class="ps-reading-15 ps-file-info-types" [innerHtml]="configFileUpload?.textExplanatory"></p>
    </div>
  </div>
</div>

<!-- <app-progress [progress]="progress"></app-progress> -->

import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  data: any;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public dataSnack: any, private matSnackBar: MatSnackBar) {
    this.data = dataSnack;
  }

  closeNotification() {
    this.matSnackBar.dismiss();
  }

  ngOnInit() {
  }

}

import { SelectiveProcesses } from './selective-processes';

export class SelectiveProcessesSerializer {

  fromJson(json: any): SelectiveProcesses {
    const sp = new SelectiveProcesses();
    if (typeof json.success !== 'undefined') {
      sp.success = json.success;
      sp.data = this.toJson(json.data);
      return sp;
    } else {
      return this.toJson(json);
    }
  }

  toJson(sp: SelectiveProcesses): any {
    if (typeof sp === 'object') {
      return {
        id: sp.id,
        portal_id: sp.portal_id,
        name: sp.name,
        unit: sp.unit,
        unit_identifier: sp.unit_identifier,
        header_message: sp.header_message,
        status: sp.status,
        edited_by: sp.edited_by,
        deleted_at: sp.deleted_at,
        created_at: sp.created_at,
        updated_at: sp.updated_at
      };
    } else {
      return sp;
    }
  }
}

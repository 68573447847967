import { ResourceService } from '../../../http/resource/resource.service';
import { HttpClient } from '@angular/common/http';
import { Stage } from './stage';
import { environment } from '@env';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SystemQueriesSerializer } from '@app/services/system-queries/system-queries.serializer';
import { SystemQueries } from '@app/services/system-queries/system-queries';

@Injectable({
  providedIn: 'root'
})
export class SaveStageSaveService extends ResourceService<SystemQueries> {
  constructor(httpClient: HttpClient, router: Router) {
    super(
      httpClient,
      `${environment.API}`,
      'selective-process/save-stage-query',
      new SystemQueriesSerializer());
  }
}


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemQueries } from '../system-queries';
import { ResourceService } from '@app/http/resource/resource.service';
import { SystemQueriesSerializer } from '../system-queries.serializer';
import { environment } from '@env';


@Injectable({
  providedIn: 'root'
})
export class TotvsQueryCacheService extends ResourceService<SystemQueries> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      `${environment.API}`,
      'settings/totvs-query-cache',
      new SystemQueriesSerializer());
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: 'pagina-nao-encontrada',
    loadChildren: () => import('./shared/components/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  },
  {
    path: 'acesso-negado',
    loadChildren: () => import('./shared/components/page-not-access/page-not-access.module').then(m => m.PageNotAccessModule)
  },
  {
    path: 'sessão-desconectada',
    loadChildren: () => import('./shared/components/page-end-session/page-end-session.module').then(m => m.PageNotAccessModule)
  },
  {
    path: 'administrativo',
    loadChildren: () => import('./layout/administration/content-layout-admin/content-layout-admin.module').then(m => m.ContentLayoutAdminModule)
  },
  {
    path: 'redirect-file',
    loadChildren: () => import('./layout/administration/redirect-file/redirect-file.module').then(m => m.RedirectFileModule)
  },
  {
    path: '',
    loadChildren: () => import('./layout/client/content-layout-client/content-layout-client.module').then(m => m.ContentLayoutClientModule)
  },
  {
    path: '',
    children: [
      {
        path: ':uri1',
        loadChildren: () =>
        import('./layout/client/content-layout-client/content-layout-client.module').then(m => m.ContentLayoutClientModule),
      },
      {
        path: ':uri1/:uri2',
        loadChildren: () =>
        import('./layout/client/content-layout-client/content-layout-client.module').then(m => m.ContentLayoutClientModule),
      },
      {
        path: ':uri1/:uri2/:uri3',
        loadChildren: () =>
        import('./layout/client/content-layout-client/content-layout-client.module').then(m => m.ContentLayoutClientModule),
      },
      {
        path: ':uri1/:uri2/:uri3/:uri4',
        loadChildren: () =>
        import('./layout/client/content-layout-client/content-layout-client.module').then(m => m.ContentLayoutClientModule),
      },
      {
        path: ':uri1/:uri2/:uri3/:uri4/:uri5',
        loadChildren: () =>
        import('./layout/client/content-layout-client/content-layout-client.module').then(m => m.ContentLayoutClientModule),
      },
      {
        path: ':uri1/:uri2/:uri3/:uri4/:uri5/:uri6',
        loadChildren: () =>
        import('./layout/client/content-layout-client/content-layout-client.module').then(m => m.ContentLayoutClientModule),
      },
      {
        path: ':uri1/:uri2/:uri3/:uri4/:uri5/:uri6',
        loadChildren: () =>
        import('./layout/client/content-layout-client/content-layout-client.module').then(m => m.ContentLayoutClientModule),
      },
      {
        path: ':uri1/:uri2/:uri3/:uri4/:uri5/:uri6/:uri7',
        loadChildren: () =>
        import('./layout/client/content-layout-client/content-layout-client.module').then(m => m.ContentLayoutClientModule),
      },
      {
        path: ':uri1/:uri2/:uri3/:uri4/:uri5/:uri6/:uri7/:uri8',
        loadChildren: () =>
        import('./layout/client/content-layout-client/content-layout-client.module').then(m => m.ContentLayoutClientModule),
      },
      {
        path: ':uri1/:uri2/:uri3/:uri4/:uri5/:uri6/:uri7/:uri8/:uri9',
        loadChildren: () =>
        import('./layout/client/content-layout-client/content-layout-client.module').then(m => m.ContentLayoutClientModule),
      },
      {
        path: ':uri1/:uri2/:uri3/:uri4/:uri5/:uri6/:uri7/:uri8/:uri9/:uri10',
        loadChildren: () =>
        import('./layout/client/content-layout-client/content-layout-client.module').then(m => m.ContentLayoutClientModule),
      },
      {
        path: '**',
        loadChildren: () => import('./shared/components/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
      },
    ]
  },
  {
    path: 'form',
    children: [
      {
        path: '',
        loadChildren: () =>
        import('@modules/client/form-test/form-test.module').then(m => m.FormModule)
      }
    ]
  },
  { path: 'applyment-access', loadChildren: () => import('./modules/client/applyment-access/applyment-access.module').then(m => m.ApplymentAccessModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }

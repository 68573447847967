import { DatePipe } from '@angular/common';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormUtils  {

  constructor() {}

  /**
   * switchValueSlideToggle
   */
  public static switchValueSlideToggleView(alt: Array<string>, date: object): any {
    for (const key in date) {
      if (date.hasOwnProperty(key)) {
        for (const iterator of alt) {
          if (key === iterator) {
            if (typeof date[key] != 'boolean') {
              if (date[key] === 0) {
                date[key] = false;
              } else {
                date[key] = true;
              }
            }
          }
        }
      }
    }
    return date;
  }

  public static switchValueSlideToggleAPI(alt: Array<string>, date: object): any {
    for (const key in date) {
      if (date.hasOwnProperty(key)) {
        for (const iterator of alt) {
          if (key === iterator) {
            if (date[key] === false) {
              date[key] = 0;
            } else {
              date[key] = 1;
            }
          }
        }
      }
    }
    return date;
  }

  /**
   * Formatted inclusion date
   * @param date date format 0000-00-00 00:00:00
   * @param person who create the user
   */
  public static dateInclusionTransform(date, person): string {
    if (date !== null) {
      const dateSplit = date.slice(0, 10).split('-');
      const hourSlice = date.slice(10, -3);
      const dateTransform = `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;
      return `${dateTransform} às ${hourSlice} por ${person}`;
    }
  }

  /**
   *
   * @param val Check for null Object
   */
  public static isObject(val) {
    if (val === null) {
      return false;
    }
    return ((typeof val === 'function') || (typeof val === 'object'));
  }

  /**
   * Parse JSON string
   */
  public static parseJSON(data) {
    data = data || '';
    return JSON.parse(data);
  }

  /**
   * Check empty object
   */
  public static checkEmptyObject(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Check if the string is empty or null
   */
  public static checkNotNullAndNotEmpty(data) {
    if (data !== null && data !== '') {
      return true;
    }
    return false;
  }

  /**
    * Check if the variable is undefined
    */
  public static isUndefined(data) {

    if (data === 'undefined') {
      return true;
    }
    return false;
  }


  /**
    * Searches for a given substring
    */
  public static contains(str, substring, fromIndex) {
    return str.indexOf(substring, fromIndex) !== -1;
  }

  /**
    * "Safer" String.toLowerCase()
    */
  public static lowerCase(str) {
    return str.toLowerCase();
  }

  /**
   * "Safer" String.toUpperCase()
   */
  public static upperCase(str) {
    return str.toUpperCase();
  }

  /**
   * UPPERCASE first char of each word.
   */
  public static properCase(str) {
    return this.lowerCase(str).replace(/^\w|\s\w/g, this.upperCase);
  }

  /**
   * UPPERCASE first char of each sentence and lowercase other chars.
   */
  public static sentenceCase(str) {
    // Replace first char of each sentence (new line or after '.\s+') to
    // UPPERCASE
    return this.lowerCase(str).replace(/(^\w)|\.\s+(\w)/gm, this.upperCase);
  }
}

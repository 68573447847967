import { SystemQueries } from './system-queries';

export class SystemQueriesSerializer {

  fromJson(json: any): SystemQueries {
    const sf = new SystemQueries();
    if (typeof json.success !== 'undefined') {
        sf.success = json.success;
        sf.data = this.toJson(json.data);
        return sf;
    } else {
        return this.toJson(json);
    }
  }

  toJson(sf: SystemQueries): any {
    if (typeof sf === 'object') {
      return {
        local_id: sf.local_id,
        portal_id: sf.portal_id,
        id: sf.id,
        title: sf.title,
        code: sf.code,
        use_cache: sf.use_cache,
        cache_interval_type_id: sf.cache_interval_type_id,
        colligate: sf.colligate,
        system: sf.system,
        client_id: sf.client_id,
        type_id: sf.type_id,
        component_id: sf.component_id,
        field_settings_id: sf.field_settings_id,
        stage_id: sf.stage_id,
        step_id: sf.step_id,
        feedback_id: sf.feedback_id,
        fields: sf.fields,
        fields_query: sf.fields_query,
        parameters: sf.parameters,
        context: sf.context,
        status: sf.status,
        status_title: sf.status_title,
        updated_at: sf.updated_at,
        cascade_change: sf.cascade_change,
        selective_process_id: sf.selective_process_id,
        description: sf.description,
        lazy_load: sf.lazy_load,
      };
    } else {
      return sf;
    }
  }
}

import { ResourceService } from '../../../http/resource/resource.service';
import { HttpClient } from '@angular/common/http';
import { VisualIdentityPortal } from './visual-identity-portal';
import { VisualIdentityPortalSerializer } from './visual-identity-portal.serializer';
import { environment } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class VisualIdentityPortalService extends ResourceService<VisualIdentityPortal> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      `${environment.API}`,
      'portal/visual-identity',
      new VisualIdentityPortalSerializer());
  }
}

import { Resource } from '../../../http/resource/resource.model';
// tslint:disable: variable-name

export class Feedback extends Resource {
  // id is inherited from Resource
  position: number;
  stage_id: number;
  background: string;
  conclusive: number;
  icon_type_id: number;
  complementary_text: string;
  cta_text: any;
  content: Array<any>;
  standard_components: Array<any>;
  standard_fields: Array<any>;
  logics: Array<any>;
  logic_enabled: boolean;
  action_logic_id: number;
  condition_logic_id: number;
  changed: number;
  stage: string;
  institutional_color: string;
}

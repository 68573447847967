import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, delay, finalize, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HTTPStatus {
  private requestInFlight$: BehaviorSubject<boolean>;
  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight);
  }

  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable();
  }
}

@Injectable()
export class HTTPListener implements HttpInterceptor {
  // tslint:disable-next-line: variable-name
  private _requests = 0;
  constructor(
    private status: HTTPStatus,
    private router: Router) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    ++this._requests;
    this.status.setHttpStatus(true);
    return next.handle(req).pipe(
      // delay(5000),
      map(event => {
        return event;
      }),
      catchError(error => {
        return throwError(error);
      }),
      finalize(() => {
        --this._requests;
        setTimeout(() => {
          this.status.setHttpStatus(this._requests > 0);
        }, 1000);
      })
    );
  }
}

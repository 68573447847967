import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { StepActionsService } from '@app/services/selective-processes/step/step-actions.service';
import { LoaderService } from '@shared/components/loader/loader.service';
import { FeedbackActionsService } from '@app/services/selective-processes/feedback/feedback-actions.service';
import { uploadProgress, filterResponse } from '@shared/utils/rxjs-operators';
import { BottomSheetRef } from '@shared/components/bottom-sheet/bottom-sheet-ref';
import { BottomSheetService } from '@shared/components/bottom-sheet/bottom-sheet.service';
import { deep, updateObjKeepingRef } from '@shared/utils/util';

@Component({
  selector: 'app-style-setting-feedback',
  templateUrl: './style-setting-feedback.component.html',
  styleUrls: ['./style-setting-feedback.component.scss']
})
export class StyleSettingFeedbackComponent implements OnInit {
  @Input() feedbackCurrent: any;
  @Input() backgroundPortal: any;
  @Output() valueChanged = new EventEmitter<any>();

  public formulario: FormGroup;
  public typeField;
  public configBackground = {
    title: 'Background',
    buttonAdd: 'Trocar imagem',
    buttonRemove: 'Remover',
    type: 'background',
    textExplanatory: 'A dimensão recomendada é de  1920 x 1080 pixels <br>' +
      '<b>Formato JPEG ou PNG de no máximo 5MB</b> <br>' +
      'Imagens com dimensões diferentes serão redimensionadas',
    acceptedType: 'png,jpeg',
    msgTypeError: 'Formato do background inválido! Tipos validos: JPEG ou PNG.',
    acceptedSize: 5000000,
    msgSizeError: 'Tamanho do background inválido! Tamanho máximo permitido 5MB.',
  };
  public progress = 0;

  constructor(
    private formBuilder: FormBuilder,
    private feedbackActionsService: FeedbackActionsService,
    public bottomSheet: BottomSheetRef,
    private loaderService: LoaderService) {
    this.formulario = this.formBuilder.group({
      background: [null]
    });
  }

  ngOnInit(): void {
    this.setValue(this.feedbackCurrent.background);
    this.formulario.valueChanges.subscribe(
      changes => {
        this.feedbackCurrent.background = changes.background;
        this.valueChanged.emit({ changed: true, to: 'style', data: changes.background });
      }
    );
  }

  onSubmit() {
    this.feedbackCurrent.logic_enabled = this.feedbackCurrent.logic_enabled ? 1 : 0;
    this.loaderService.setChangesLoaderState(true);
    if (this.feedbackCurrent.id === null) {
      delete this.feedbackCurrent.id;
      this.feedbackActionsService.upload(this.feedbackCurrent).pipe(
        uploadProgress(progress => {
          this.progress = progress;
        }),
        filterResponse()
      ).subscribe((response: any) => {
        if (response.success) {
          this.formulario.patchValue({ background: response.data.background });
          this.feedbackCurrent.background = response.data.background;
          updateObjKeepingRef(this.feedbackCurrent.content, response.data.content);
          this.feedbackCurrent.id = response.data.id;
          this.feedbackCurrent.logics = response.data.logics;
          this.loaderService.setChangesLoaderState(false, 'Arquivo salvo com sucesso!', 'ok', 'success');
        } else {
          this.loaderService.setChangesLoaderState(false, 'Erro ao salvar o arquivo, tente novamente.', 'ok', 'error');
        }
      },
        error => {
          this.loaderService.setChangesLoaderState(false,
            error.body,
            '',
            'error',
            error.title,
            100000);
        }
      );
    } else {
      this.feedbackActionsService.upload(this.feedbackCurrent).pipe(
        uploadProgress(progress => {
          this.progress = progress;
        }),
        filterResponse()
      ).subscribe((response: any) => {
        if (response.success) {
          this.formulario.patchValue({ background: response.data.background });
          this.feedbackCurrent.background = response.data.background;
          updateObjKeepingRef(this.feedbackCurrent.content, response.data.content);
          this.feedbackCurrent.id = response.data.id;
          this.feedbackCurrent.logics = response.data.logics;
          this.loaderService.setChangesLoaderState(false, 'Arquivo salvo com sucesso!', 'ok', 'success');
        } else {
          this.loaderService.setChangesLoaderState(false, 'Erro ao salvar o arquivo, tente novamente.', 'ok', 'error');
        }
      },
        error => {
          this.loaderService.setChangesLoaderState(false,
            error.body,
            '',
            'error',
            error.title,
            100000);
        }
      );
    }
  }

  private setValue(back) {
    this.formulario.setValue({ background: back });
  }

  get f() { return this.formulario.controls; }

}

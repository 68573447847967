import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddsPareamentsService {

  public array = [];
  public arrayTwo = [];
  public status = [];

  constructor() { }

  /**
   * setValuesParameters
   */
  public setValuesParameters(array, parameters_control_name = 'parameters') {
    this.array[parameters_control_name] = array;
  }

  /**
   * setValuesParametersTwo
   */
  public setValuesParametersTwo(array, parameters_control_name = 'parameters') {
    this.arrayTwo[parameters_control_name] = array;
  }

  /**
   * setStatus
   */
  public setStatus(param, parameters_control_name = 'parameters') {
    this.status[parameters_control_name] = param;
  }

}

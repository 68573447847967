import { Component } from './component';

export class ComponentSerializer {

  fromJson(json: any): any {
    const sf = new Component();
    if (typeof json.success !== 'undefined') {
        sf.success = json.success;
        sf.data = this.toJson(json.data);
        return sf;
    } else {
        return this.toJson(json);
    }
  }

  toJson(sf: Component): any {
    return sf;
  }
}

import { ResourceService } from '../../../http/resource/resource.service';
import { HttpClient } from '@angular/common/http';
import { Stage } from './stage';
import { StageSerializer } from './stage.serializer';
import { environment } from '@env';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StageOpeningService extends ResourceService<Stage> {
  constructor(httpClient: HttpClient, router: Router) {
    super(
      httpClient,
      `${environment.API}`,
      // { endpoint: 'selective-process/opening-page-stages', router, splitPostion: 0 },
      'selective-process/opening-page-stages',
      new StageSerializer());
  }
}

import { Component, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DialogComponent } from '../../dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DialogConfirmationService } from './dialog-confirmation.service';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.scss']
})
export class DialogConfirmationComponent implements OnInit {

  public dados: any;
  private obj: any;

  @Output() deleteCustomField = new EventEmitter();

  constructor(
    private dateComponent: DialogComponent,
    private dialogRef: MatDialogRef<DialogComponent>,
    private dialogConfirmatioService: DialogConfirmationService,
    private dialog: MatDialog) {
    // console.log(dialogRef);
    this.dados = dialogConfirmatioService.getDadosDialogConfirm();
  }

  ngOnInit() {}

  onClickConfirmation() {
    if (this.dateComponent.data.click) {
      this.dateComponent.data.click.emit();
    }
    this.dialogRef.close();
  }

  onClickCancel() {
    this.dialogRef.close();
  }

}

<div class="ps_formBuilder_formContainer">
  <div fxLayout="row wrap" class="ps_formBuilder_formContainer_margin">
    <span class="ps_formBuilder_text" fxFlex="100">Condição lógica</span>
    <mat-slide-toggle
      fxFlex="100"
      [formControl]="disableLogic"
      [color]="'primary'"
    >
      {{ disableLogic.value == true ? "Ativado" : "Desativado" }}
    </mat-slide-toggle>
    <p class="ps_formBuilder_text" style="font-size: 14px; margin-top: 28px" fxFlex="100">
      {{info !== '' ? info : 'Crie regras para mostrar ou ocultar esse elemento, dependendo dos valores de outros campos.'}}
    </p>
    <div class="ps_formBuilder_conditionals" fxFlex="100">
      <div fxLayout="row" fxLayoutAlign="space-around">
        <mat-form-field style="position: relative;" appearance="fill" fxFlex="40">
          <mat-label>Ação</mat-label>
          <mat-select [formControl]="action" required>
            <mat-option *ngFor="let option of actions" [value]="option.value">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span fxFlex="5"></span>
        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Condição</mat-label>
          <mat-select [formControl]="condition" [placeholder]="" required>
            <mat-option
              *ngFor="let option of conditions"
              [value]="option.value"
            >
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <p
        [hidden]="!action.valid || !condition.valid"
        *ngIf="rules.length == 0 && disableLogic.value"
        class="ps_formBuilder_noRules"
      >
        Ainda não há regras lógicas, clique em "Adicionar regra lógica" para
        adicionar uma.
      </p>
    </div>
    <!-- form array -->
    <div
      class="ps_formBuilder_rules"
      [formGroup]="rulesFields"
      fxLayout="row wrap"
      fxFlex="100"
    >
      <div
        formArrayName="formArray"
        class="ps_formBuilder_rule mat-elevation-z1"
        *ngFor="let rule of rules; let i = index"
        fxFlex="100"
        fxLayout="row"
        fxLayoutAlign="start"
      >
        <div [formGroupName]="i">
          <mat-form-field appearance="fill" fxFlex="20">
            <mat-label>Campo</mat-label>
            <input type="text" placeholder="Campo" aria-label="Campo" matInput [matAutocomplete]="auto"
              formControlName="key">

            <mat-autocomplete #auto="matAutocomplete" [displayWith]="display">
              <mat-option
              *ngFor="let option of filteredOptions[i] | async; trackBy: trackByValue"
              [value]="option.value"
              [matTooltip]="option.name"
              matTooltipPosition="below"
              matTooltipClass="ps-tooltip-content"
              [matTooltipShowDelay]="200">
              <span style="position: relative;">
                {{ option.name }}
              </span>
              <span style="font-size: 10px">({{ option.value }})</span>
            </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <span fxFlex="1"></span>
          <mat-form-field appearance="fill" fxFlex="35">
            <mat-label>Regra</mat-label>
            <mat-select formControlName="operator" required>
              <mat-option
                *ngFor="let option of operators"
                [value]="option.value"
              >
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span fxFlex="1"></span>
          <mat-form-field
            *ngIf="
              getForm(i).controls.operator.value !== '' &&
              !(
                getForm(i).controls.operator.value == 3 ||
                getForm(i).controls.operator.value == 4
              )
            "
            appearance="fill"
            fxFlex
          >
            <mat-label>Insira um valor</mat-label>
            <input *ngIf="
            !checkSelectBoxesType(getForm(i).controls.key.value) &&
            !checkDateType(getForm(i).controls.key.value) &&
            !(
              getForm(i).controls.operator.value == 5 ||
              getForm(i).controls.operator.value == 6
            )"
              matInput
              required
              formControlName="value"
              placeholder="Insira um valor"
            />
            <input
            *ngIf="
            (
              getForm(i).controls.operator.value == 5 ||
              getForm(i).controls.operator.value == 6
            ) && !checkDateType(getForm(i).controls.key.value) && !checkSelectBoxesType(getForm(i).controls.key.value)"
            matInput
            type="number"
            formControlName="value"
            required
            placeholder="Insira um valor"
          />
          <mat-select
            required
            placeholder="Selecione um valor"
            *ngIf="checkSelectBoxesType(getForm(i).controls.key.value)"
            formControlName="value">
            <mat-option value="true">Selecionado</mat-option>
            <mat-option value="false">Não selecionado</mat-option>
          </mat-select>
          <input
          (click)="picker.open()"
          matInput
          [matDatepicker]="picker"
          formControlName="value"
          readonly
          required
          placeholder="Insira uma data"
          [ngClass]="{'hideValueInput': !checkDateType(getForm(i).controls.key.value)}"
        />
        <mat-datepicker-toggle *ngIf="checkDateType(getForm(i).controls.key.value)" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
          <span fxFlex="10"></span>
          <button
            [disabled]="!disableLogic.value"
            (click)="removeRule(i)"
            mat-icon-button
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div class="ps_formBuilder_addRule">
        <a
        [disabled]="!disableLogic.value"
        *ngIf="(action.valid && condition.valid) && disableLogic.value"
        mat-stroked-button
        color="primary"
        (click)="createRule({key: '', operator: '', value: ''})"
        >
        <mat-icon>add</mat-icon> Adicionar regra lógica
      </a>
    </div>
  </div>
  <!-- form array -->
  </div>
</div>

import { ResourceService } from '../../http/resource/resource.service';
import { HttpClient } from '@angular/common/http';
import { SelectiveProcesses } from './selective-processes';
import { SelectiveProcessesSerializer } from './selective-processes.serializer';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class SelectiveProcessesOpeningPageService extends ResourceService<SelectiveProcesses> {
  constructor(httpClient: HttpClient, router: Router) {
    super(
      httpClient,
      `${environment.API}`,
      // { endpoint: 'selective-process/opening-page-process', router, splitPostion: 0 },
      'selective-process/opening-page-process',
      new SelectiveProcessesSerializer());
  }
}

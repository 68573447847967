import { NgControl } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[disableControl]'
})
export class DisableControlDirective {

  @Input() set disableControl(condition: any) {
    setTimeout(() => {
      if (condition === false) {
        this.ngControl.control.disable();
      } else if (condition === null || condition === 'null') {
        this.ngControl.control.disable();
      } else {
        this.ngControl.control.enable();
      }
    });
  }

  constructor(private ngControl: NgControl) { }

}

import { ResourceService } from '../../http/resource/resource.service';
import { HttpClient } from '@angular/common/http';
import { SystemQueriesSerializer } from './system-queries.serializer';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { SystemQueries } from './system-queries';

@Injectable({
  providedIn: 'root'
})
export class SaveEditQueryDataService extends ResourceService<SystemQueries> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      `${environment.API}`,
      'settings/totvs-query/',
      new SystemQueriesSerializer());
  }
}
import { EventEmitter, Injectable, SimpleChanges } from '@angular/core';
import { MatTabGroup, MatTab, MatTabHeader } from '@angular/material/tabs';
import { ConfirmService } from '@shared/components/confirm/confirm.service';
import { DataSourceStepFeedbackModel } from '@app/model/data-source-step-feedback.model';
import { noUndefined } from '@angular/compiler/src/util';
import { emit } from 'process';

@Injectable({
  providedIn: 'root',
})
export class SystemSettingService {

  public tabs;
  public interceptValue = true;

  public tabChange = new EventEmitter();

  constructor(private confirmService: ConfirmService, private dataSourceStepFeedbackModel: DataSourceStepFeedbackModel) {
  }


  getMatTabGroup(tabs: MatTabGroup) {
    this.tabs = tabs;
    this.tabs._handleClick = this.interceptTabChange.bind(this);
  }

  interceptTabChange(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
    if (!this.interceptValue) {
      this.confirmService
        .openConfirm(
          'Sair da página ?',
          'As alterações feitas não serão salvas.',
          'Sair',
          'Cancelar',
          true
        )
        .afterClosed()
        .subscribe(success => {
          if (success) {
            this.dataSourceStepFeedbackModel.valueTwo = undefined;
            this.tabChange.emit(success);
          }
          this.interceptValue = success;
          return success && MatTabGroup.prototype._handleClick.apply(this.tabs, [tab, tabHeader, idx]);
        });
    } else {
      return this.interceptValue && MatTabGroup.prototype._handleClick.apply(this.tabs, [tab, tabHeader, idx]);
    }
  }

  setInterceptTabChange(e) {
    this.interceptValue = e;
  }
}

import { Resource } from '../../../http/resource/resource.model';

export class Stage extends Resource {
  // id is inherited from Resource
  // tslint:disable: variable-name
  // id: number;
  action_logic_id: number;
  stage_id: number;
  condition_logic_id: number;
  logic_enabled: boolean;
  logics: Array<any>;
  selective_process_id: number;
  feedback: Array<any>;
  title: string;
  status: number;
  position: number;
  steps?: Array<any>;
  query?: Array<any>;
  parameters?: Array<any>;
  stages_positions?: Array<any>;
  deleted_at: string;
  created_at: string;
  updated_at: string;
}

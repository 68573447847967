<div class="ps-dialog-container" fxLayout="row" [ngClass]="{'ps-header-dialog-red': dados.headerRed}" *ngIf="action !== 'confirm'">
  <div fxFlex fxLayoutAlign="start center">
    <span [title]="dados.titleDialog" class="ps-dialog-title">{{dados.titleDialog}}</span>
  </div>
  <div fxFlex="5" class="max-width" fxLayoutAlign="end center" *ngIf="closeButton">
    <mat-icon class="ps-dialog-close" (click)="onNoClick()">close</mat-icon>
  </div>
</div>
<div class="ps-dialog-container-confirm" fxLayout="row" *ngIf="action === 'confirm'">
  <div fxFlex fxLayoutAlign="start center">
    <span class="ps-title-medium">{{dados.titleDialog}}</span>
  </div>
</div>
<div class="ps-dialog-content" id="ps-scroll-dialog" [ngClass]="data.contentDialogClassName">
  <mat-dialog-content id="ps-scroll-dialog">
    <ng-template #target></ng-template>
  </mat-dialog-content>
</div>

